import { FormsServiceClient } from 'pp-vuex-web-services'
import UserContext from '@/entities/UserContext'
import FormSchema from '@/entities/FormSchema'
import * as types from './mutations-types'
import { datadogRum } from '@datadog/browser-rum'

/**
 * Decrypts the magic token and stores result in state
 * @param commit
 * @param token
 * @returns {*}
 */
export const decryptToken = ({ commit }, token) => {
  const client = new FormsServiceClient(false)
  return client.post('v1/public/magic-link/decrypt', { token })
    .then((response) => {
      commit(types.SET_USER_CONTEXT, new UserContext(response))
    })
    .catch((e) => {
      datadogRum.addError(e, {
        vuex: 'forms/actions/decryptToken',
        error: e.response || e.message,
        token
      })
      return Promise.reject(new Error('Error decrypting magic link'))
    })
}

/**
 * Gets the form data of specific form
 * @param commit
 * @param formId
 * @param preview
 * @returns {*}
 */
export const getFormData = ({ commit }, { formId, preview }) => {
  const client = new FormsServiceClient(false)
  return client.get(`v1/public/forms/${formId}/${preview ? 'preview' : 'version'}`)
    .then((response) => {
      commit(types.SET_FORMS_DATA, [new FormSchema(response)])
    })
    .catch((e) => {
      datadogRum.addError(e, {
        vuex: 'forms/actions/getFormData',
        error: e.response || e.message,
        formId,
        preview
      })
      return Promise.reject(new Error('Error getting form data'))
    })
}

/**
 * Get forms associated with an appointment
 * @param commit
 * @param appointmentId
 * @returns {*}
 */
export const getAppointmentForms = ({ commit }, appointmentId) => {
  const client = new FormsServiceClient(false)
  return client.get(`/v1/public/appointment-forms?calendar_booking_id=${appointmentId}`)
    .then((response) => {
      const formSchemas = response.data.map((formSchema) => new FormSchema(formSchema))
      commit(types.SET_FORMS_DATA, formSchemas)
    })
    .catch((e) => {
      datadogRum.addError(e, {
        vuex: 'forms/actions/getAppointmentForms',
        error: e.response || e.message,
        appointmentId
      })
      return Promise.reject(new Error('Error getting form data'))
    })
}

/**
 * Get forms associated with a patient type
 * @param commit
 * @param patientType
 * @returns {*}
 */
export const getPatientTypeForms = ({ commit }, patientType) => {
  const client = new FormsServiceClient(false)
  const params = {
    page_size: 999,
    page_key: 0,
    practice_id: client.practiceUuid,
    patient_type: patientType
  }
  return client.get('/v1/public/patient-forms', params)
    .then((response) => {
      const formSchemas = response.data.map((formSchema) => new FormSchema(formSchema))
      commit(types.SET_FORMS_DATA, formSchemas)
    })
    .catch((e) => {
      datadogRum.addError(e, {
        vuex: 'forms/actions/getPatientTypeForms',
        error: e.response || e.message,
        dataToSend: params,
        patientType
      })
      return Promise.reject(new Error('Error getting form data'))
    })
}

/**
 * Submitting a form
 * @param commit
 * @param state
 * @param data
 * @returns {*}
 */
export const submitForm = ({ commit, state }, data) => {
  const client = new FormsServiceClient(false)
  const dataToSend = {
    form_version_id: data.id,
    context: {
      calendar_booking_id: state.userContext.appointmentId,
      patient_id: state.userContext.patientId,
      patient_type: state.userContext.patientType
    },
    form: data.formValues
  }
  return client.post(`v1/public/forms/versions/${data.id}/submissions`, dataToSend)
    .then(() => {
      commit(types.SET_NEXT_FORM)
    })
    .catch((e) => {
      datadogRum.addError(e, {
        vuex: 'forms/actions/submitForm',
        error: e.response || e.message,
        dataToSend,
        data
      })
      return Promise.reject(new Error('Error submitting form'))
    })
}
