<template>
  <pp-container>
    <pp-loader v-if="isLoading" />
    <pp-row v-else>
      <pp-col
        :sm="multipleForms ? 8 : 12"
        class="order-2 order-sm-1"
        cols="12"
      >
        <form-layout
          v-if="currentForm"
          :key="currentForm.formId"
        />
        <pp-status-banner
          v-else
          status="warning"
          title="No Forms Available"
          text="There are currently no forms available."
        />
      </pp-col>
      <pp-col
        v-if="multipleForms"
        class="order-1 order-sm-2"
        cols="12"
        sm="4"
      >
        <forms-nav />
      </pp-col>
    </pp-row>
  </pp-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormLayout from '@/components/form/layout/FormLayout'
import FormsNav from '@/components/forms-nav/FormsNav'

export default {
  name: 'ViewsForm',
  components: {
    'form-layout': FormLayout,
    'forms-nav': FormsNav
  },
  data () {
    return {
      isLoading: true
    }
  },
  computed: {
    ...mapGetters({
      userContext: 'forms/userContext',
      completedForms: 'forms/completed',
      forms: 'forms/forms',
      currentForm: 'forms/currentForm'
    }),
    multipleForms () {
      return this.forms.length > 1
    }
  },
  watch: {
    // when forms are completed redirect to confirmation page
    completedForms (value) {
      if (value === true) {
        this.$router.push({ name: 'Confirmation' })
      }
    }
  },
  mounted () {
    // if there is no token redirect to error page
    if (!this.$route.query.token) {
      this.$router.push({ name: 'Error' })
      return
    }

    this.decryptToken(this.$route.query.token)
      .then(() => {
        if (this.userContext.formId) {
          this.getFormData({ formId: this.userContext.formId, preview: this.$ppPreview })
            .then(() => {
              this.isLoading = false
            })
            .catch((e) => {
              this.setSnackMessage({
                message: e.message
              })
              this.$ppAnalytics.trackEvent({
                action: 'api_error',
                label: 'get-form-data'
              })
              this.$router.push({ name: 'Error' })
            })
        } else if (this.userContext.appointmentId) {
          this.getAppointmentForms(this.userContext.appointmentId)
            .then(() => {
              this.isLoading = false
            })
            .catch((e) => {
              this.setSnackMessage({
                message: e.message
              })
              this.$ppAnalytics.trackEvent({
                action: 'api_error',
                label: 'get-appointment-forms'
              })
              this.$router.push({ name: 'Error' })
            })
        } else if (this.userContext.patientType) {
          this.getPatientTypeForms(this.userContext.patientType)
            .then(() => {
              this.isLoading = false
            })
            .catch((e) => {
              this.setSnackMessage({
                message: e.message
              })
              this.$ppAnalytics.trackEvent({
                action: 'api_error',
                label: 'get-patient-type-forms'
              })
              this.$router.push({ name: 'Error' })
            })
        }
      })
      .catch((e) => {
        this.$ppAnalytics.trackEvent({
          action: 'api_error',
          label: 'decrypt-token'
        })
        this.$router.push({ name: 'Error' })
      })
  },
  methods: {
    ...mapActions({
      setSnackMessage: 'ui/setSnackMessage',
      decryptToken: 'forms/decryptToken',
      getFormData: 'forms/getFormData',
      getAppointmentForms: 'forms/getAppointmentForms',
      getPatientTypeForms: 'forms/getPatientTypeForms'
    })
  }
}
</script>
