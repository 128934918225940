import * as types from './mutations-types'

export default {
  [types.SET_USER_CONTEXT] (state, userContext) {
    state.userContext = userContext
  },
  [types.SET_FORMS_DATA] (state, data) {
    state.forms = data

    // set the first form as the current form
    state.currentForm = data[0]
  },
  [types.SET_NEXT_FORM] (state) {
    const currentIndex = state.forms.findIndex((form) => form.formId === state.currentForm.formId)
    state.forms[currentIndex].completed = true

    if (state.forms.length > currentIndex + 1) {
      state.currentForm = state.forms[currentIndex + 1]
    } else {
      state.completed = true
    }
  }
}
