<template>
  <pp-app>
    <router-view />
    <pp-snack-bar :snack-message="snackMessage" />
  </pp-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { reportLoadedSuccessfully } from 'sdk-widget-manager'

export default {
  name: 'App',
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
      snackMessage: 'ui/snackMessage'
    })
  },
  mounted () {
    try {
      reportLoadedSuccessfully('portal.digital-registration')
    } catch (_) {
      this.setSnackMessage({
        message: 'Error loading',
        status: 'error'
      })

      this.$ppAnalytics.trackEvent({
        action: 'api_error',
        label: 'widget-load'
      })
    }
  },
  created () {
  },
  methods: {
    ...mapActions({
      setSnackMessage: 'ui/setSnackMessage'
    })
  }
}
</script>

<style lang="scss" scoped>
.pp-app {
  .container {
    max-width: 1188px;
  }
}

</style>
