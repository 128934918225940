import Vue from 'vue'
import Vuex from 'vuex'
import { ui } from 'pp-vuex-web-services'
import forms from './forms'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  state: {},
  mutations: {},
  actions: {},
  modules: {
    ui,
    forms
  }
})
