<template>
  <pp-panel class="mb-10">
    <template #body>
      <h2 class="text-h2">
        {{ section.title }}
      </h2>
      <p>{{ section.description }}</p>
      <form-field
        v-for="field in section.fields"
        :key="field.id"
        :ref="`field-${field.id}`"
        :field="field"
        @input="emitToSection"
      />
    </template>
  </pp-panel>
</template>

<script>
import FormField from '@/components/form/field/FormField'

export default {
  name: 'FormSection',
  components: {
    'form-field': FormField
  },
  props: {
    section: {
      type: Object,
      required: true
    }
  },
  methods: {
    emitToSection ({ id, value }) {
      this.$emit('input', { id, value })
      this.$ppAnalytics.trackEvent({
        action: 'field_input'
      })
    }
  }
}
</script>
