<template>
  <pp-panel
    ref="formsNav"
    class="forms-nav"
    :style="`--forms-nav-margin-top:${cssMarginTop}`"
  >
    <template #body>
      <ol class="pl-0 forms-nav__list">
        <template v-for="(form, index) in forms">
          <li
            :key="form.formId"
            :class="`forms-nav__item--${formStatus[form.formId].cssModifier}`"
            class="align-center forms-nav__item"
          >
            <i
              :class="`pp-icon-${formStatus[form.formId].icon}`"
              class="mr-4 d-flex align-center justify-center forms-nav__item__icon pa-4"
            />
            <div class="forms-nav__item__title font-weight-bold text-truncate pr-2">
              {{ form.name }}
            </div>
            <div class="forms-nav__item__counter d-sm-none flex-grow-1 flex-shrink-0 text-right">
              {{ index + 1 }} / {{ forms.length }}
            </div>
          </li>
        </template>
      </ol>
    </template>
  </pp-panel>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FormsNav',
  data () {
    return {
      cssMarginTop: ''
    }
  },
  computed: {
    ...mapGetters({
      forms: 'forms/forms',
      currentForm: 'forms/currentForm'
    }),
    formStatus () {
      const result = {}

      this.forms.forEach((form) => {
        const formId = form.formId
        result[formId] = {}
        if (formId === this.currentForm.formId) {
          result[formId].cssModifier = 'current'
          result[formId].icon = 'edit'
        } else if (form.completed) {
          result[formId].cssModifier = 'complete'
          result[formId].icon = 'check'
        } else {
          result[formId].cssModifier = 'todo'
          result[formId].icon = 'document'
        }
      })
      return result
    }
  },
  mounted () {
    if (window.parentIFrame) {
      window.parentIFrame.getPageInfo((pageInfo) => {
        if (pageInfo.iframeWidth > 600) {
          const maxValue = pageInfo.iframeHeight - this.$refs.formsNav.$el.clientHeight
          const newMarginTop = pageInfo.scrollTop - pageInfo.offsetTop
          if (newMarginTop < maxValue) {
            this.cssMarginTop = `${newMarginTop}px`
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.forms-nav {
  $self: &;

  position: fixed;
  top: 0;
  width: calc(100% - 1.5em);

  @include desktop() {
    margin-top: var(--forms-nav-margin-top, 0);
    position: sticky;
    top: 1em;
    transition: margin 200ms;
    width: 100%;
  }

  &__list {
    list-style: none;
  }

  &__item {
    display: none; // hide items on mobile and only show current one
    position: relative;

    @include mobile() {
      // on hover expand to show all items
      #{$self}:hover & {
        display: flex;
        margin-bottom: 1em;
      }
    }

    @include desktop() {
      display: flex;
      margin-bottom: 1em;
    }

    // show a grey line connecting each item
    &:before {
      background: $grey-30;
      content: "";
      display: block;
      height: calc(100% + 1em);
      left: 1.425em;
      position: absolute;
      top: 0;
      width: 2px;
      z-index: 0;
      // on mobile hide until on hover
      @include mobile() {
        display: none;
        #{$self}:hover & {
          display: block;
        }
      }
    }

    // ensure last items grey line doesn't extend over
    &:last-child {
      &:before {
        height: 100%;
      }
    }

    &--current {
      --status-color: #{$blue-50};

      // show current item on mobhile
      @include mobile() {
        display: flex;
      }
    }

    &--complete {
      --status-color: #{$green-50};
    }

    &__icon {
      background: var(--status-color, #{$grey-40});
      border-radius: 50%;
      color: $white;
      text-align: center;
      z-index: 0;
    }

    &__title {
      color: var(--status-color, #{$grey-70});
    }

    &__counter {
      color: $grey-50;

      &:first-letter {
        color: $grey-70;
        font-size: 1.1em;
        font-weight: bolder;
      }
    }
  }
}

</style>
