<template>
  <div class="form-field-display">
    <pp-input-textarea
      v-if="field.type === fieldTypeIds.LONG_TEXT"
      v-model="fieldInputValue"
      :description="fieldDescription"
      :error-messages="getErrorMessages()"
      :label="field.question"
      :placeholder="field.placeholder"
      @blur="blurField(field.question)"
      @focus="scrollToView()"
    />
    <pp-input-text
      v-else-if="field.type === fieldTypeIds.NUMBER"
      v-model="fieldInputValue"
      :description="fieldDescription"
      :error-messages="getErrorMessages()"
      :hide-details="false"
      :label="field.question"
      :placeholder="field.placeholder"
      type="number"
      @blur="blurField(field.question)"
      @focus="scrollToView()"
    />
    <pp-input-text
      v-else-if="field.type === fieldTypeIds.PHONE_NUMBER"
      v-model="fieldInputValue"
      v-mask="'(###) ### ####'"
      :description="fieldDescription"
      :error-messages="getErrorMessages()"
      :hide-details="false"
      :label="field.question"
      :placeholder="field.placeholder"
      type="tel"
      @blur="blurField(field.question)"
      @focus="scrollToView(); $v.fieldInputValue.$reset()"
    />
    <pp-input-text
      v-else-if="field.type === fieldTypeIds.EMAIL_ADDRESS"
      v-model="fieldInputValue"
      :description="fieldDescription"
      :error-messages="getErrorMessages()"
      :hide-details="false"
      :label="field.question"
      :placeholder="field.placeholder"
      type="email"
      @blur="blurField(field.question)"
      @focus="scrollToView(); $v.fieldInputValue.$reset()"
    />
    <pp-input-date
      v-else-if="field.type === fieldTypeIds.DATE"
      v-model="fieldInputValue"
      :description="fieldDescription"
      :error-messages="getErrorMessages()"
      :label="field.question"
      :native="$ppUi.isMobile"
      :placeholder="field.placeholder"
      @blur="blurField(field.question)"
      @focus="scrollToView(); $v.fieldInputValue.$reset()"
      @chosen="trackDateInput"
    />
    <pp-input-radio-group
      v-else-if="field.type === fieldTypeIds.RADIO_BUTTON"
      v-model="fieldInputValue"
      :description="fieldDescription"
      :error-messages="getErrorMessages()"
      :label="field.question"
      :options="field.options"
      name="field-options"
      @blur="blurField(field.question)"
      @focus="scrollToView()"
    />
    <template v-else-if="field.type === fieldTypeIds.CHECKBOX">
      <label v-if="field.question">{{ field.question }}</label>
      <div
        v-if="fieldDescription"
        class="subtitle-1 my-2 input-description"
      >
        {{ fieldDescription }}
      </div>
      <div
        v-if="getErrorMessages()"
        class="error--text"
      >
        <template v-for="errorMessage in getErrorMessages()">
          {{ errorMessage }}
        </template>
      </div>
      <pp-input-checkbox
        v-for="option in field.options"
        :key="option.value"
        :label="option.label"
        @input="chooseCheckbox($event, option)"
      />
    </template>
    <template v-else-if="field.type === fieldTypeIds.FILE">
      <label>{{ field.question }}</label>
      <div
        v-if="fieldDescription"
        class="subtitle-1 my-2 input-description"
      >
        {{ fieldDescription.replace(' less than 200 MB.', '') }}
      </div>

      <div
        v-if="getErrorMessages()"
        class="error--text"
      >
        <template v-for="errorMessage in getErrorMessages()">
          {{ errorMessage }}
        </template>
      </div>
      <pp-input-image-upload
        v-model="fieldInputValue"
        :formats="['image/jpg', 'image/jpeg', 'image/png', 'image/gif']"
        :max-width="4920"
        :max-height="4920"
        :status="uploadStatus"
        @change="scrollToView(); blurField(field.question)"
        @clear="onImageClear"
        @error="onImageError"
      />
    </template>
    <pp-input-text
      v-else
      v-model="fieldInputValue"
      :description="fieldDescription"
      :error-messages="getErrorMessages()"
      :hide-details="false"
      :label="field.question"
      :placeholder="field.placeholder"
      @blur="blurField(field.question)"
      @focus="scrollToView();"
    />
  </div>
</template>

<script>
import { VueMaskDirective } from 'v-mask'
import { INPUT_TYPES_IDS } from '@/constants/input-types'
import FIELD_NAMES from '@/constants/field-names'
import { mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { requiredIf, email } from 'vuelidate/lib/validators'
import { validPhone } from 'pp-fe-utils'

export default {
  name: 'FormField',
  directives: { mask: VueMaskDirective },
  mixins: [validationMixin],
  props: {
    field: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      fieldInputValue: null,
      fieldTypeIds: INPUT_TYPES_IDS,
      checkboxOptions: {},
      uploadStatus: ''
    }
  },
  computed: {
    fieldDescription () {
      return this.field.hasDescription && this.field.description ? this.field.description : ''
    }
  },
  watch: {
    // on value change, emit the value change
    fieldInputValue () {
      // for file input, handle the file
      if (this.field.type === this.fieldTypeIds.FILE) {
        const reader = new FileReader()
        reader.readAsDataURL(this.fieldInputValue)
        reader.onload = () => {
          this.$emit('input', {
            id: this.field.id,
            value: {
              name: this.fieldInputValue.name,
              payload: reader.result
            }
          })
        }
        reader.onError = () => {
          this.setSnackMessage({
            message: 'Failed to read file.'
          })

          this.$ppAnalytics.trackEvent({
            action: 'file_reader_error',
            label: reader.error
          })
        }
      } else if (this.field.type === this.fieldTypeIds.DATE) { // for date, need to format date when emitting to parent
        this.$emit('input', { id: this.field.id, value: this.formatDate(this.fieldInputValue) })
      } else {
        this.$emit('input', { id: this.field.id, value: this.fieldInputValue })
      }
    }
  },
  methods: {
    ...mapActions({
      setSnackMessage: 'ui/setSnackMessage'
    }),
    validatePhone (phone, allowNull = false) {
      if (!phone && !allowNull) {
        return false
      } else if (!phone && allowNull) {
        return true
      }
      return validPhone(phone)
    },
    validDate (date, allowNull = false) {
      if (!date && !allowNull) {
        return false
      } else if (!date && allowNull) {
        return true
      }

      if (date && date.length !== 10) {
        return false
      }

      return !isNaN(new Date(date))
    },
    validDob (dob = null) {
      // validate date of birth is in the past
      const yesterday = new Date()
      yesterday.setDate(yesterday.getDate() - 1)

      return dob !== null && new Date(dob) < yesterday
    },

    getErrorMessages () {
      const errors = []
      if (!this.$v.fieldInputValue.$dirty) {
        return errors
      }

      // Required error message
      !this.$v.fieldInputValue.required && errors.push('Required')

      if (this.$v.fieldInputValue.validDate && this.$v.fieldInputValue.validDob === false) {
        errors.push('Date of birth should be in the past')
      } else if (this.$v.fieldInputValue.$invalid && errors.length === 0) {
        // Default error message
        errors.push('Please enter a valid value')
      }

      return errors
    },
    // when fields are updated, touch them and track accordingly
    blurField (fieldName) {
      // trim input value if its a string
      if (this.fieldInputValue && typeof this.fieldInputValue === 'string') {
        this.fieldInputValue = this.fieldInputValue.trim()
      }
      this.$v.fieldInputValue.$touch()
      if (this.$v.fieldInputValue.$error) {
        this.$ppAnalytics.trackEvent({
          action: 'input_error',
          label: fieldName
        })
        return
      }
      this.$ppAnalytics.trackEvent({
        action: 'input',
        label: fieldName
      })
    },
    // scroll the field into view
    scrollToView () {
      this.$el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
    },
    // for checkboxes set the value to be a key/value pair based on the options chosen
    chooseCheckbox (value, option) {
      this.checkboxOptions[option.label] = value
      this.fieldInputValue = this.checkboxOptions
      this.$emit('input', { id: this.field.id, value: this.fieldInputValue })
    },
    onImageError (e) {
      this.setSnackMessage({ message: e })
      this.$ppAnalytics.trackEvent({
        action: 'image_upload_error',
        label: `error: ${e}`
      })
    },
    onImageClear () {
      this.$emit('input', { id: this.field.id, value: '' })
      this.$ppAnalytics.trackEvent({
        action: 'image_upload_clear'
      })
    },
    formatDate (date) {
      if (!date) {
        return null
      }

      // if date is of format YYYY-MM-DD then format
      if (date.match(/^\d{4}-\d{2}-\d{2}$/)) {
        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      }

      return date
    },
    trackDateInput (methodOfChoosingDate) {
      this.$ppAnalytics.trackEvent({
        action: 'date_input',
        label: methodOfChoosingDate
      })
    }
  },
  validations () {
    if (this.field.type === this.fieldTypeIds.EMAIL_ADDRESS) {
      return {
        fieldInputValue: {
          required: requiredIf(() => this.field.required),
          email
        }
      }
    } else if (this.field.type === this.fieldTypeIds.PHONE_NUMBER) {
      return {
        fieldInputValue: {
          required: requiredIf(() => this.field.required),
          validatePhone: (v) => this.validatePhone(v, !this.field.required)
        }
      }
    } else if (this.field.fieldName === FIELD_NAMES.DATE_OF_BIRTH) {
      return {
        fieldInputValue: {
          required: requiredIf(() => this.field.required),
          validDate: (v) => this.validDate(v, !this.field.required),
          validDob: (v) => this.validDob(v)
        }
      }
    } else if (this.field.type === this.fieldTypeIds.DATE) {
      return {
        fieldInputValue: {
          required: requiredIf(() => this.field.required),
          validDate: (v) => this.validDate(v, !this.field.required)
        }
      }
    }

    return {
      fieldInputValue: {
        required: requiredIf(() => this.field.required)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.input-description {
  white-space: pre-line;
}
</style>
