/*
 *  INPUT_TYPES_IDS indicate the format of the form fields
 */

export const INPUT_TYPES_IDS = {
  SHORT_TEXT: 'shortText',
  LONG_TEXT: 'longText',
  NUMBER: 'number',
  PHONE_NUMBER: 'phoneNumber',
  EMAIL_ADDRESS: 'emailAddress',
  DATE: 'date',
  RADIO_BUTTON: 'radioButton',
  CHECKBOX: 'checkbox',
  FILE: 'file',
  SECTION: 'section'
}
