import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/pp-vue-library'
import breakpoints from '@/styles/breakpoints.scss'
import PpVueAnalytics from 'pp-vue-analytics'
import { datadogRum } from '@datadog/browser-rum'
import { Resizer } from 'sdk-widget-manager'
import { getStoredUrlParams } from 'pp-fe-utils'
import { LOCAL_STORAGE_PARAMS_KEY } from '@/constants/widget'

Vue.use(Resizer)
Vue.config.productionTip = false

const params = getStoredUrlParams(LOCAL_STORAGE_PARAMS_KEY)

const appGaId = process.env.VUE_APP_GA_ID
const practiceGaId = params.get('gaId')
Vue.use(PpVueAnalytics, router, {
  appGaId,
  practiceGaId,
  category: 'digital-forms',
  trackEventPrefix: 'digital_forms',
  practiceUuid: params.get('practiceUuid')

})

Vue.prototype.$ppUi = {
  isMobile: window.innerWidth < breakpoints.xs
}
Vue.prototype.$vuetify = {
  rtl: false,
  lang: {
    t: (val) => val
  },
  theme: {
    dark: false
  },
  breakpoint: {
    mobile: true,
    mobileBreakpoint: 600
  },
  icons: {
    values: {
      prev: 'mdi-chevron-left',
      next: 'mdi-chevron-right',
      dropdown: 'mdi-chevron-down',
      first: 'mdi-page-first',
      last: 'mdi-page-last',
      unfold: 'mdi-unfold-more-horizontal'
    }
  }
}

Vue.prototype.$practiceUuid = params.get('practiceUuid')
Vue.prototype.$ppToken = params.get('token')
Vue.prototype.$ppPreview = params.get('preview')
Vue.prototype.$ppColors = {
  primary: params.get('color_palette[primary]'),
  secondary: params.get('color_palette[secondary]'),
  accent: params.get('color_palette[accent]')
}

datadogRum.init({
  applicationId: '5e2af509-7805-494d-8a4b-a9de3dfdd5cf',
  clientToken: 'pub7bbf89cf63b9610db8f22f90a22c2857',
  env: process.env.VUE_APP_STAGE,
  version: process.env.VUE_APP_VERSION,
  sampleRate: 100,
  site: 'datadoghq.com',
  service: 'digitial-forms-ui',
  useCrossSiteSessionCookie: true,
  trackInteractions: true,
  trackFrustrations: true,
  beforeSend: (event, context) => {
    // collect a RUM resource's response headers
    if (event.type === 'resource' && event.resource.type === 'fetch') {
      event.context = { ...event.context, responseHeaders: context.response.headers }
    }
  }
})

datadogRum.addRumGlobalContext('practiceUuid', params.get('practiceUuid'))

datadogRum.startSessionReplayRecording()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
