import Vue from 'vue'
import VueRouter from 'vue-router'
import ViewsForm from '@/views/form/ViewsForm'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    component: ViewsForm,
    meta: {
      title: () => 'Form'
    }
  },
  {
    path: '/confirmation',
    name: 'Confirmation',
    component: () => import(/* webpackChunkName: "confirmation" */ '../views/confirmation/ViewsConfirmation.vue'),
    meta: {
      title: () => 'Confirmation'
    }
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import(/* webpackChunkName: "error" */ '../views/error/ViewsError'),
    meta: {
      title: () => 'Error'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
