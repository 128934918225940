<template>
  <form
    v-if="formData"
    :class="{'form-layout--loading': isSubmitting}"
    class="form-layout"
    @submit.prevent="submitForm"
  >
    <div class="form-layout__header">
      <h1>
        {{ formName }}
      </h1>
      <p>{{ formData.instructions }}</p>
    </div>

    <form-section
      v-for="section in formData.sections"
      :key="section.id"
      :ref="`section-${section.id}`"
      :section="section"
      @input="updateFormPayload"
    />
    <pp-row
      v-if="!$ppPreview"
      class="d-flex align-center form-layout__footer"
    >
      <pp-col class="text-body-2 flex-grow-1 flex-shrink-0 d-none d-sm-flex">
        <template v-if="forms.length > 1">
          Continue to complete this form and move onto the next.
        </template>
        Please review to ensure the details are correct before completion.
      </pp-col>
      <pp-col
        class="text-right flex-grow-0 flex-shrink-1"
        cols="12"
        sm="auto"
      >
        <pp-button
          :block="$ppUi.isMobile"
          :loading="isSubmitting"
          submit
          text="Submit"
          type="primary"
        />
      </pp-col>
    </pp-row>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormSection from '@/components/form/section/FormSection'

export default {
  name: 'FormLayout',
  components: {
    'form-section': FormSection
  },
  data () {
    return {
      formAnswers: {},
      isSubmitting: false
    }
  },
  computed: {
    ...mapGetters({
      forms: 'forms/forms',
      formData: 'forms/currentForm'
    }),
    formName () {
      return this.formData?.name
    }
  },
  mounted () {
    if (window.parentIFrame) {
      window.parentIFrame.scrollTo(0, 0)
    }
  },
  methods: {
    ...mapActions({
      setSnackMessage: 'ui/setSnackMessage',
      sendFormData: 'forms/submitForm'
    }),
    submitForm () {
      this.isSubmitting = true

      let isFormValid = true
      let topPositionOfError = 0

      // validate each field
      Object.values(this.$refs).forEach((section) => {
        Object.values(section[0].$refs).forEach((field) => {
          // touch all fields
          field[0].$v.$touch()

          // if any field is invalid, set the form to invalid
          if (field[0].$v.$invalid) {
            isFormValid = false
            // note the position to use for scrolling back to
            if (field[0].$el.getBoundingClientRect().top < topPositionOfError) {
              topPositionOfError = field[0].$el.getBoundingClientRect().top
              field[0].scrollToView()
            }
          }
        })
      })

      if (isFormValid) {
        // send the form answers
        this.sendFormData({
          formValues: this.formAnswers,
          id: this.formData.id
        })
          .then(() => {
            this.setSnackMessage({
              message: 'Form Submitted',
              status: 'success'
            })
            this.$ppAnalytics.trackEvent({
              action: 'form_submit'
            })
          })
          .catch((e) => {
            this.setSnackMessage({
              message: e.message
            })

            this.$ppAnalytics.trackEvent({
              action: 'form_submit_error'
            })
          })
          .finally(() => {
            this.isSubmitting = false
          })
      } else {
        this.isSubmitting = false

        this.setSnackMessage({
          message: 'Please ensure all fields are filled out correctly.'
        })

        this.$ppAnalytics.trackEvent({
          action: 'form_submit_invalid'
        })
      }
    },
    updateFormPayload ({ id, value }) {
      this.formAnswers = { ...this.formAnswers, [id]: value }
    }
  }
}
</script>
<style lang="scss" scoped>
.form-layout {
  position: relative;

  @include mobile() {
    margin-top: 5.5em;
  }

  &--loading {
    &:before {
      background: rgba(255, 255, 255, .05);
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 99;
    }
  }

  &__header {
    @include desktop() {
      background-image: url('https://ui-cdn.patientpop.com/assets/img/illustrative-icons/invoice.svg');
      background-position: center left;
      background-size: 60px;
      padding-left: 5em;
    }
  }

  &__footer {
    @include mobile() {
      background: $white;
      border-top: 1px solid $grey-40;
      bottom: 0;
      position: sticky;
    }
  }
}

</style>
