export default class FormSchema {
  constructor (data = {}) {
    this.id = data.id || null
    this.name = data.form_name || null
    this.formId = data.form_id || null
    this.sections = data.schema?.sections || null
    this.instructions = data.schema?.instructions || null
    this.completed = false
  }
}
